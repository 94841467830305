<template>
    <v-form ref="rental_history">
        <form-section
            :sectionTitle="$t('applications.rental-history')"
            wideFirstCol
        >
            <!-- searchable, -->
            <template v-slot:col-1>
                <div
                    v-for="{
                        address,
                        type,
                        status,
                        meta,
                        landlord,

                        removeable,
                        editable,
                    } in entitiesData"
                    :key="address.id"
                >
                    <!-- Control bar shows type {current,previous} and allows for removal if not current -->
                    <control-tool-bar
                        :type="type"
                        :title="$t('applications.address')"
                        :removeable="removeable"
                        @remove="removeEntity(address.id)"
                    ></control-tool-bar>
                    <!-- Force selecting a status for the address  -->
                    <select-rental-status
                        :type="type"
                        :status="status"
                        @update="updateStatus(address.id, $event)"
                    >
                    </select-rental-status>

                    <!-- @searchable="
                                update(address.id, 'searchable', $event)
                            " -->
                    <v-slide-y-transition>
                        <!-- First search before show -->
                        <google-address-search
                            v-if="status && editable"
                            :type="type"
                            @update="update(address.id, 'address', $event)"
                        ></google-address-search>
                    </v-slide-y-transition>

                    <!-- &&                        ((status && address.address) || !searchable) -->
                    <v-slide-y-reverse-transition>
                        <div v-if="editable">
                            <v-divider class="mb-6"></v-divider>
                            <applicant-address
                                :addressData="address"
                                @update="update(address.id, 'address', $event)"
                            ></applicant-address>
                            <div
                                v-if="
                                    status !== 'own-my-home' &&
                                    $_.get(
                                        sections,
                                        'rental-history-landlord',
                                        true
                                    )
                                "
                            >
                                <v-divider class="mb-6"></v-divider>
                                <landlord-employer
                                    :key="`${address.id}-landlord-employer-${status}`"
                                    :modelData="landlord"
                                    @update="
                                        update(address.id, 'landlord', $event)
                                    "
                                ></landlord-employer>
                            </div>
                            <v-divider
                                class="mb-6"
                                v-if="
                                    $_.get(
                                        sections,
                                        'rental-history-meta',
                                        true
                                    )
                                "
                            ></v-divider>
                            <!-- Key by status forces render when status changes to pickup label changes -->
                            <rental-meta
                                v-if="
                                    $_.get(
                                        sections,
                                        'rental-history-meta',
                                        true
                                    )
                                "
                                :key="`${address.id}-rental-meta-${status}`"
                                @update="update(address.id, 'meta', $event)"
                                :metaData="meta"
                                :type="type"
                            ></rental-meta>
                        </div>
                    </v-slide-y-reverse-transition>
                </div>
                <rental-history-notice
                    v-if="showAddressNotice"
                    :complete="rentalHistoryCountComplete"
                ></rental-history-notice>

                <v-row v-if="entityIsLoading">
                    <v-col cols="12">
                        <psi-content-loader></psi-content-loader>
                    </v-col>
                </v-row>
                <v-row v-if="showAddAddress">
                    <v-col cols="12" class="my-2">
                        <v-btn
                            color="primary"
                            dark
                            @click="createEntity('address')"
                            :loading="entityIsLoading"
                        >
                            <v-icon left>add_location</v-icon
                            >{{ $t("applications.add-address") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </form-section>
    </v-form>
</template>

<script>
import InteractsWithEntities from "@components/application/mixins/InteractsWithEntities";

export default {
    name: "rental-history",
    mixins: [InteractsWithEntities],
    components: {
        ApplicantAddress: () => import("./ApplicantAddress"),
        GoogleAddressSearch: () => import("./GoogleAddressSearch"),
        LandlordEmployer: () => import("./LandlordEmployer"),
        RentalMeta: () => import("./RentalMeta"),
        ControlToolBar: () => import("./ControlToolBar"),
        SelectRentalStatus: () => import("./SelectRentalStatus"),
        RentalHistoryNotice: () => import("./RentalHistoryNotice"),
    },
    props: {
        sections: {
            type: Object,
            required: true,
        },
    },
    computed: {
        /**
         * Determines if we should show the notice for adding additional addresses
         *
         * @return boolean
         */
        showAddressNotice() {
            return (
                this.entitiesData.every(
                    (addr) => addr["status"] || addr["removeable"]
                ) && !this.isStudent
            );
        },
        /**
         * See if we hit the max number of preferred addresses
         *
         * @return boolean
         */
        rentalHistoryCountComplete() {
            return (
                this.entitiesData.length ===
                this.$config("applications.maxRentalAddressCount")
            );
        },
        /**
         * Enable adding additonal addresses
         *
         * @return boolean
         */
        showAddAddress() {
            return (
                this.entitiesData.every(
                    (addr) => addr["status"].length > 0 || addr["removeable"]
                ) &&
                this.entitiesData.length <
                    this.$config("applications.maxRentalAddressCount") &&
                this.entitiesData.find(
                    (item) => item.status === "no-address"
                ) === undefined
            );
        },
    },

    data() {
        return {
            // Name is used by parent during validation
            name: this.$t("applications.rental-history"),
            // Entity ID Key is used to find/ update our entity elements in the array of entities
            entityIdKey: "address.id",
        };
    },
    watch: {},

    methods: {
        /**
         * Maps the initial load of entities to a setup of params driving the UI
         *
         * @param {Object} elem
         * @param {int} index
         * @return {Object} modified element
         */
        mapEntityElement(elem, index) {
            // Not searchable if we already have a street address
            elem["searchable"] = elem["address"].street.length === 0;
            // We can't remove current and first previous address
            elem["removeable"] = index >= 2;
            // We have a fake address for HART API when an applicant selects "NO ADDRESS". We don't want to edit/show this address
            elem["editable"] =
                elem["address"].street !==
                this.$config("applications.fakeAddress.street");
            return elem;
        },
        /**
         * Updates the status for the address element and creates dynamic label properties for landlord / meta
         *
         * @param {any} id
         * @return void
         */
        updateStatus(id, { status, label, payment_label }) {
            this.update(id, "landlord", { label });
            this.update(id, "meta", { payment_label });

            let elem = {
                status,
            };
            if (status === "no-address") {
                this.update(
                    id,
                    "address",
                    this.$config("applications.fakeAddress")
                );
                elem["editable"] = false;
            } else {
                elem["editable"] = true;
            }
            // We have to update root keys at the same time
            this.update(id, "root", elem);
        },
        /**
         * Initializes an address element post create. Called from the mixin on create
         *
         * @param {Object} entity
         * @return void
         */
        postCreateEntity(entity) {
            // Post processing to setup the address
            entity["type"] = "previous"; // added entityes are always previous
            entity["removeable"] = true; // any added entity is removeable
            entity["searchable"] = true; // default allow Google search by entity
            entity["editable"] = true;

            return entity;
        },
        /**
         * Validate the section ensuring required fields are met
         *
         * @return void
         */
        validate() {
            return this.$refs["rental_history"].validate();
        },
    },
};
</script>

<style scoped>
</style>