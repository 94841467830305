var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "rental_history" },
    [
      _c("form-section", {
        attrs: {
          sectionTitle: _vm.$t("applications.rental-history"),
          wideFirstCol: ""
        },
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _vm._l(_vm.entitiesData, function(ref) {
                  var address = ref.address
                  var type = ref.type
                  var status = ref.status
                  var meta = ref.meta
                  var landlord = ref.landlord
                  var removeable = ref.removeable
                  var editable = ref.editable
                  return _c(
                    "div",
                    { key: address.id },
                    [
                      _c("control-tool-bar", {
                        attrs: {
                          type: type,
                          title: _vm.$t("applications.address"),
                          removeable: removeable
                        },
                        on: {
                          remove: function($event) {
                            return _vm.removeEntity(address.id)
                          }
                        }
                      }),
                      _c("select-rental-status", {
                        attrs: { type: type, status: status },
                        on: {
                          update: function($event) {
                            return _vm.updateStatus(address.id, $event)
                          }
                        }
                      }),
                      _c(
                        "v-slide-y-transition",
                        [
                          status && editable
                            ? _c("google-address-search", {
                                attrs: { type: type },
                                on: {
                                  update: function($event) {
                                    return _vm.update(
                                      address.id,
                                      "address",
                                      $event
                                    )
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-slide-y-reverse-transition", [
                        editable
                          ? _c(
                              "div",
                              [
                                _c("v-divider", { staticClass: "mb-6" }),
                                _c("applicant-address", {
                                  attrs: { addressData: address },
                                  on: {
                                    update: function($event) {
                                      return _vm.update(
                                        address.id,
                                        "address",
                                        $event
                                      )
                                    }
                                  }
                                }),
                                status !== "own-my-home" &&
                                _vm.$_.get(
                                  _vm.sections,
                                  "rental-history-landlord",
                                  true
                                )
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-divider", {
                                          staticClass: "mb-6"
                                        }),
                                        _c("landlord-employer", {
                                          key:
                                            address.id +
                                            "-landlord-employer-" +
                                            status,
                                          attrs: { modelData: landlord },
                                          on: {
                                            update: function($event) {
                                              return _vm.update(
                                                address.id,
                                                "landlord",
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.$_.get(
                                  _vm.sections,
                                  "rental-history-meta",
                                  true
                                )
                                  ? _c("v-divider", { staticClass: "mb-6" })
                                  : _vm._e(),
                                _vm.$_.get(
                                  _vm.sections,
                                  "rental-history-meta",
                                  true
                                )
                                  ? _c("rental-meta", {
                                      key:
                                        address.id + "-rental-meta-" + status,
                                      attrs: { metaData: meta, type: type },
                                      on: {
                                        update: function($event) {
                                          return _vm.update(
                                            address.id,
                                            "meta",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                }),
                _vm.showAddressNotice
                  ? _c("rental-history-notice", {
                      attrs: { complete: _vm.rentalHistoryCountComplete }
                    })
                  : _vm._e(),
                _vm.entityIsLoading
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [_c("psi-content-loader")],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showAddAddress
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "my-2", attrs: { cols: "12" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  dark: "",
                                  loading: _vm.entityIsLoading
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.createEntity("address")
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("add_location")
                                ]),
                                _vm._v(
                                  _vm._s(_vm.$t("applications.add-address")) +
                                    " "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }